import React from "react"
import { useSelector } from "react-redux"
import { useLocation, Navigate } from "react-router-dom"

const UserAccessAuthorizationComponent = ({ children }) => {
  const { user_info } = useSelector(state => state.agibUserLogin)
  const location = useLocation()
  // useEffect(()=>{

  // },[])
  if (!user_info.token) {
    return <Navigate to="/auth" state={{ from: location }} replace />
  }

  return children
}

export default UserAccessAuthorizationComponent
