import React from "react"
import { useSelector } from "react-redux"
import { ThemeProvider } from "styled-components"
import { dark, light } from "./colors"
import { fonts, font_sizes, font_weights } from "./fonts"
import { sizes } from "./sizes"
import { space, line_heights } from "./spacing"
import device from "./devices"

const Index = ({ children }) => {
  const { mood } = useSelector(state => state.agibTheme)
  const colors = mood ? dark : light
  const theme = { colors, device, fonts, font_sizes, font_weights, line_heights, sizes, space }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Index
