import styled from "styled-components"

export const SideNavMenu = styled("div")`
  /* background: ${props => props.theme.colors.foreground.primary}; */
  box-shadow: 0px 10px 20px -10px ${props => props.theme.colors.ui.shadow};
  height: 100%;
  width: 15vw; /* 0 width  */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 5vh; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 1rem;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
  }
`

export const SideNavList = styled("ul")`
  list-style-type: none;
  padding: 0.01rem;
`

export const TitleText = styled("span")`
  font-size: ${props => props.theme.font_sizes.body};
  color: ${props => props.theme.colors.text.primary};
`
export const MenuIcon = styled("span")`
  color: ${props => props.theme.colors.brand.primary};
  font-size: ${props => props.theme.font_sizes.icon};
`

export const SideNaveListItem = styled("li")`
  background: ${props => props.active && props.theme.colors.foreground.primary};
  font-size: ${props => props.theme.font_sizes.h4};
  font-weight: ${props => props.theme.font_weights.bold};
  justify-content: center;
  text-align: left;
  margin: 0.1rem 0rem;
  padding: 0.5rem;
  width: 100%;
  border-radius: 1px;
  border-bottom: 0.5px solid ${props => props.theme.colors.brand.primary};
  transition: 0.4s;
  color:${props => props.theme.colors.text.primary}

  &:hover {
    transition: 0.5s;
    background: ${props => props.theme.colors.brand.primary};
  }
  &:hover ${TitleText} {
    color: ${props => props.theme.colors.text.primary};

  }
`

export const NavLink = styled("a")`
  border-radius: 2px;
  padding: 0.5rem;
  margin: 1rem;
  color: #fff;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2 ease-in-out;
  text-decoration: none;
  outline: none;

  &:active {
    color: #15cdfc;
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    color: #616f39;
    border-bottom: 5px solid #616f39;
    // border-radius: 0px;
    text-decoration: none;
    // background-color: #ffffff;
  }
`
