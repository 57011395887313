import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
const AdminAccessAuthorizationComponent = ({ children }) => {
  const { user_info } = useSelector(state => state.agibUserLogin)

  if (user_info && user_info.token && user_info.isAdmin) {
    return children
  } else {
  }
  return <Navigate to="/" />
}

export default AdminAccessAuthorizationComponent
