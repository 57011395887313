import {
  MINISTATEMENT_REQUEST,
  MINISTATEMENT_SUCCESS,
  MINISTATEMENT_FAIL,
  MINISTATEMENT_RESET,
  UPDATE_STATEMENT_STATUS_REQUEST,
  UPDATE_STATEMENT_STATUS_SUCCESS,
  UPDATE_STATEMENT_STATUS_FAIL,
  UPDATE_STATEMENT_STATUS_RESET,
} from "./constants"

export const miniStatementReducer = (state = { account_number: "", statements: [] }, action) => {
  switch (action.type) {
    case MINISTATEMENT_REQUEST:
      return { ...state, loading: true }
    case MINISTATEMENT_SUCCESS:
      return {
        loading: false,
        account_number: action.payload.account_number,
        statements: action.payload.statements,
        from_date: action.payload.start_date,
        to_date: action.payload.to_date,
        acc_amount: action.payload.acc_amount,
        user_acted_on: action.payload.user_acted_on,
        ref_no: action.payload.ref_no,
      }
    case MINISTATEMENT_RESET:
      return { statements: state.statements, loading: false }
    case MINISTATEMENT_FAIL:
      return { loading: false, account_number: "", statements: [], error: action.payload }
    default:
      return state
  }
}

export const updateStatementStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STATEMENT_STATUS_REQUEST:
      return { loading: true }
    case UPDATE_STATEMENT_STATUS_SUCCESS:
      return { loading: false, success: action.payload }
    case UPDATE_STATEMENT_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_STATEMENT_STATUS_RESET:
      return {}
    default:
      return state
  }
}
