import axios from "axios"
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DEACTIVATE_REQUEST,
  USER_DEACTIVATE_SUCCESS,
  USER_DEACTIVATE_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
} from "./constants"

const { REACT_APP_BASEURL } = process.env

export const loginAction = (email, password, token) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })
    const config = {
      headers: { "Content-Type": "application/json" },
    }
    let { data } = await axios.post(`${REACT_APP_BASEURL}/api/user/login`, { email, password, token }, config)
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
    localStorage.setItem("agibUserInfo", JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}

export const deactivateUserAction = id => async dispatch => {
  try {
    dispatch({ type: USER_DEACTIVATE_REQUEST })
    let { data } = await axios.put(`${REACT_APP_BASEURL}/api/user/d/${id}`)
    dispatch({ type: USER_DEACTIVATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_DEACTIVATE_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}

export const activateUserAction = id => async dispatch => {
  try {
    dispatch({ type: USER_ACTIVATE_REQUEST })
    let { data } = await axios.put(`${REACT_APP_BASEURL}/api/user/a/${id}`)
    dispatch({ type: USER_ACTIVATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}

export const forgotPasswordAction = email => async dispatch => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST })

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    let { data } = await axios.post(`${REACT_APP_BASEURL}/api/user/password/forgot`, { email }, config)
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}

export const resetPasswordAction = (reset_token, reset_id) => async dispatch => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST })

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    let { data } = await axios.get(
      `${REACT_APP_BASEURL}/api/user/password/reset?reset_token=${reset_token}&reset_id=${reset_id}`,
      config
    )
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}

export const logoutUserAction = () => dispatch => {
  localStorage.removeItem("agibUserInfo")
  dispatch({ type: USER_LOGOUT })
}

export const updatePasswordAction = passwords => async dispatch => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST })
    let { data } = await axios.put(`${REACT_APP_BASEURL}/api/user/password/change`, passwords)
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response ? error.response.data : error.message,
    })
  }
}
