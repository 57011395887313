import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

import {
  agibUserRegisterReducer,
  agibUsersGetReducer,
  agibUserEditReducer,
  agibUserProfileReducer,
  agibUserAddAccountReducer,
  agibUserRemoveAccountReducer,
} from "./users/reducer"
import {
  agibUserLoginReducer,
  agibUserActivateReducer,
  agibUserDeactivateReducer,
  agibForgetPasswordReducer,
  agibResetPasswordReducer,
  agibUpdatePasswordReducer,
} from "./authentication/reducers"
import { miniStatementReducer, updateStatementStatusReducer } from "./statements/reducer"
import { partialReversalReducer, updateReversalReducer } from "./reversals/reducers"
import { exportToExcelRedcuer } from "./exports/reducers"
import { themeReducer } from "./theme/reducers"

const reducer = combineReducers({
  agibUserLogin: agibUserLoginReducer,
  agibUserRegister: agibUserRegisterReducer,
  agibUsersGet: agibUsersGetReducer,
  agibUserDeactivate: agibUserDeactivateReducer,
  agibUserActivate: agibUserActivateReducer,
  agibUserEdit: agibUserEditReducer,
  agibUserProfile: agibUserProfileReducer,
  agibUserAddAccount: agibUserAddAccountReducer,
  agibUserRemoveAccount: agibUserRemoveAccountReducer,
  agibForgetPassword: agibForgetPasswordReducer,
  agibResetPassword: agibResetPasswordReducer,
  agibUpdatePassword: agibUpdatePasswordReducer,
  updateReversal: updateReversalReducer,
  miniStatement: miniStatementReducer,
  updateStatementStatus: updateStatementStatusReducer,
  exportToExcel: exportToExcelRedcuer,
  partialReversal: partialReversalReducer,
  agibTheme: themeReducer,
})

const agib_user_info_from_local_store =
  localStorage.getItem("agibUserInfo") && localStorage.getItem("agibUserInfo") !== "undefined"
    ? JSON.parse(localStorage.getItem("agibUserInfo"))
    : {}

const initialState = {
  agibUserLogin: { user_info: agib_user_info_from_local_store },
}
const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))
// composeWithDevTools(applyMiddleware(...middleware))

export default store
// 3882848
