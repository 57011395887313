import React from "react"
import { BrowserRouter as Router, useRoutes, Link } from "react-router-dom"
import Loader from "../../components/Loader"
import UserAccessAuthorizationComponent from "../../components/authorization/UserAccessAuthorizationComponent"
import AdminAccessAuthorizationComponent from "../../components/authorization/AdminAccessAuthorizationComponent"
import RenderStructureComponent from "../layout/index"
const { REACT_APP_BASENAME } = process.env

const dynamicImport = page => {
  return React.lazy(() =>
    import(`../../features/${page}`)
      .then(p => {
        return p
      })
      .catch(err => console.log(err))
  )
}
const DashboardPage = dynamicImport("home/pages/")
const LoginPage = dynamicImport("authentication/pages/LoginPage")
const ForgotPasswordPage = dynamicImport("authentication/pages/ForgotPassword")
const ResetPasswordPage = dynamicImport("authentication/pages/ResetPassword")
const AdminPage = dynamicImport("admin/pages/")
const PaymentConfirmationPage = dynamicImport("payments/pages/")

const ProfilePage = dynamicImport("users/pages/")

const ReversalPage = dynamicImport("reversals/pages/")
const AppRoutesConfiguration = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <React.Suspense fallback={<Loader />}>
          <UserAccessAuthorizationComponent>
            <RenderStructureComponent>
              <DashboardPage />
            </RenderStructureComponent>
          </UserAccessAuthorizationComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/auth",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<Loader />}>
              <LoginPage />
            </React.Suspense>
          ),
        },
        {
          path: "/auth/password/forgot",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ForgotPasswordPage />
            </React.Suspense>
          ),
        },
        {
          path: "/auth/password/reset",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ResetPasswordPage />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <React.Suspense fallback={<Loader />}>
          <UserAccessAuthorizationComponent>
            <AdminAccessAuthorizationComponent>
              <RenderStructureComponent>
                <AdminPage />
              </RenderStructureComponent>
            </AdminAccessAuthorizationComponent>
          </UserAccessAuthorizationComponent>
        </React.Suspense>
      ),
      children: [
        {
          path: "/admin/users",
          element: (
            <React.Suspense fallback={<Loader />}>
              <UserAccessAuthorizationComponent>
                <AdminPage />
              </UserAccessAuthorizationComponent>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/payment",
      element: (
        <React.Suspense fallback={<Loader />}>
          <UserAccessAuthorizationComponent>
            <RenderStructureComponent>
              <PaymentConfirmationPage />
            </RenderStructureComponent>
          </UserAccessAuthorizationComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/reversal",
      element: (
        <React.Suspense fallback={<Loader />}>
          <UserAccessAuthorizationComponent>
            <RenderStructureComponent>
              <ReversalPage />
            </RenderStructureComponent>
          </UserAccessAuthorizationComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/profile",
      element: (
        <React.Suspense fallback={<Loader />}>
          <UserAccessAuthorizationComponent>
            <RenderStructureComponent>
              <ProfilePage />
            </RenderStructureComponent>
          </UserAccessAuthorizationComponent>
        </React.Suspense>
      ),
    },
    {
      path: "*",
      element: <NoMatch />,
    },
  ])

  return routes
}

const RouteWrapper = () => {
  return (
    <Router basename={REACT_APP_BASENAME}>
      <AppRoutesConfiguration />
    </Router>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
export default RouteWrapper
