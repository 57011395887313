import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { FaPowerOff, FaRegLightbulb, FaMoon } from "react-icons/fa"
import { Link } from "react-router-dom"
import Switch from "react-switch"

import { TopNavBar, BrandMenu, BrandName, WordBank, UserMenu, LogoutButton, ThemeToggler } from "./HeaderStyles"
import { logoutUserAction } from "../../services/authentication/actions"
import { changeTheme } from "../../services/theme/action"

const HeaderComponent = () => {
  const dispatch = useDispatch()
  const { mood } = useSelector(state => state.agibTheme)
  const logoutHandler = () => {
    dispatch(logoutUserAction())
  }

  const themeMoodHandler = _mood => {
    dispatch(changeTheme(_mood))
  }

  return (
    <header>
      <TopNavBar>
        <BrandMenu>
          <Link to="/" style={{ textDecoration: "none" }}>
            <BrandName>AGI</BrandName>
            <WordBank>Bank</WordBank>
          </Link>
        </BrandMenu>
        <UserMenu>
          <ThemeToggler>
            <Switch
              height={35}
              width={75}
              checked={mood}
              onColor={"#fff"}
              offColor={"#000"}
              onHandleColor="#616f39"
              offHandleColor="#616f39"
              uncheckedIcon={<FaMoon color="#fff" />}
              checkedIcon={<FaRegLightbulb color="#f9a825" />}
              onChange={e => {
                themeMoodHandler(e)
              }}
            />
          </ThemeToggler>
          <LogoutButton onClick={logoutHandler}>
            <FaPowerOff />
          </LogoutButton>
        </UserMenu>
      </TopNavBar>
    </header>
  )
}

export default HeaderComponent
