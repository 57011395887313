import React from "react"
import { MainBody } from "../../components/StyledComponents"
import SideNavMenuBarComponent from "../routing/components/SideNavMenuBarComponent"
import HeaderComponent from "../../components/Header/HeaderComponent"
const RenderStructureComponent = ({ children }) => {
  return (
    <>
      <HeaderComponent />
      <SideNavMenuBarComponent />
      <MainBody>{children}</MainBody>
    </>
  )
}

export default RenderStructureComponent
