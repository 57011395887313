export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGOUT = "USER_LOGOUT"

export const RESET_PASSWORD_REQUEST = "PASSWORD_RECOVERY_REQUEST"
export const RESET_PASSWORD_SUCCESS = "PASSWORD_RECOVERY_SUCCESS"
export const RESET_PASSWORD_FAIL = "PASSWORD_RECOVERY_FAIL"
export const RESET_PASSWORD_RESET = "PASSWORD_RECOVERY_FAIL_RESET"

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET"

export const USER_DEACTIVATE_REQUEST = "USER_DEACTIVATE_REQUEST"
export const USER_DEACTIVATE_SUCCESS = "USER_DEACTIVATE_SUCCESS"
export const USER_DEACTIVATE_FAIL = "USER_DEACTIVATE_FAIL"
export const USER_DEACTIVATE_RESET = "USER_DEACTIVATE_RESET"

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST"
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS"
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL"
export const USER_ACTIVATE_RESET = "USER_ACTIVATE_RESET"
