import { EX2XL_REQUEST, EX2XL_SUCCESS, EX2XL_FAIL, EX2XL_RESET } from "./constants"

export const exportToExcelRedcuer = (state = {}, action) => {
  switch (action.type) {
    case EX2XL_REQUEST:
      return { loading: true }
    case EX2XL_SUCCESS:
      return { success: true, file: action.payload }
    case EX2XL_FAIL:
      return { loading: false, error: action.payload }
    case EX2XL_RESET:
      return {}
    default:
      return state
  }
}
