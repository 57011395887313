import {
  PARTIAL_REVERSAL_REQUEST,
  PARTIAL_REVERSAL_SUCCESS,
  PARTIAL_REVERSAL_FAIL,
  UPDATE_REVERSAL_REQUEST,
  UPDATE_REVERSAL_SUCCESS,
  UPDATE_REVERSAL_FAIL,
  UPDATE_REVERSAL_RESET,
} from "./constants"

export const partialReversalReducer = (state = { account_number: "", reversals: [] }, action) => {
  switch (action.type) {
    case PARTIAL_REVERSAL_REQUEST:
      return { ...state, loading: true }
    case PARTIAL_REVERSAL_SUCCESS:
      return {
        loading: false,
        reversals: action.payload.reversals,
        account_number: action.payload.account_number,
      }
    case PARTIAL_REVERSAL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateReversalReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REVERSAL_REQUEST:
      return { loading: false }
    case UPDATE_REVERSAL_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_REVERSAL_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_REVERSAL_RESET:
      return {}
    default:
      return state
  }
}
