import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DEACTIVATE_REQUEST,
  USER_DEACTIVATE_SUCCESS,
  USER_DEACTIVATE_FAIL,
  USER_DEACTIVATE_RESET,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_RESET,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_RESET,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_RESET,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_RESET,
} from "./constants"

export const agibUserLoginReducer = (state = { user_info: {} }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, user_info: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, user_info: {}, error: action.payload }
    case USER_LOGOUT:
      return { user_info: {} }
    default:
      return state
  }
}

export const agibForgetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { loading: true }
    case FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case FORGOT_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export const agibResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true }
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case RESET_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export const agibUserDeactivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DEACTIVATE_REQUEST:
      return { ...state, loading: true }
    case USER_DEACTIVATE_SUCCESS:
      return { loading: false, success: true }
    case USER_DEACTIVATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_DEACTIVATE_RESET:
      return {}
    default:
      return state
  }
}

export const agibUserActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return { ...state, loading: true }
    case USER_ACTIVATE_SUCCESS:
      return { loading: false, success: true }
    case USER_ACTIVATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_ACTIVATE_RESET:
      return {}
    default:
      return state
  }
}

export const agibUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return { loading: true }
    case UPDATE_PASSWORD_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}
