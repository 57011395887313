export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"
export const USER_REGISTER_RESET = "USER_REGISTER_RESET"

export const USERS_GET_REQUEST = "USERS_GET_REQUEST"
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS"
export const USERS_GET_FAIL = "USERS_GET_FAIL"
export const USERS_GET_UPDATED_USER = "USERS_GET_UPDATED_USER"
export const USERS_GET_UPDATED_USER_ACCOUNTS = "USERS_GET_UPDATED_USER_ACCOUNTS"

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST"
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS"
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL"
export const USER_PROFILE_RESET = "USER_PROFILE_RESET"

export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST"
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS"
export const USER_EDIT_FAIL = "USER_EDIT_FAIL"
export const USER_EDIT_RESET = "USER_EDIT_RESET"

export const USER_ADD_ACCOUNT_REQUEST = "USER_ADD_ACCOUNT_REQUEST"
export const USER_ADD_ACCOUNT_SUCCESS = "USER_ADD_ACCOUNT_SUCCESS"
export const USER_ADD_ACCOUNT_FAIL = "USER_ADD_ACCOUNT_FAIL"
export const USER_ADD_ACCOUNT_RESET = "USER_ADD_ACCOUNT_RESET"

export const USER_REMOVE_ACCOUNT_REQUEST = "USER_REMOVE_ACCOUNT_REQUEST"
export const USER_REMOVE_ACCOUNT_SUCCESS = "USER_REMOVE_ACCOUNT_SUCCESS"
export const USER_REMOVE_ACCOUNT_FAIL = "USER_REMOVE_ACCOUNT_FAIL"
export const USER_REMOVE_ACCOUNT_RESET = "USER_REMOVE_ACCOUNT_RESET"

export const USER_PROFILE_MOOD = "USER_PROFILE_MOOD"
