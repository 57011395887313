import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_EDIT_RESET,
  USER_ADD_ACCOUNT_REQUEST,
  USER_ADD_ACCOUNT_SUCCESS,
  USER_ADD_ACCOUNT_FAIL,
  USER_ADD_ACCOUNT_RESET,
  USER_REMOVE_ACCOUNT_REQUEST,
  USER_REMOVE_ACCOUNT_SUCCESS,
  USER_REMOVE_ACCOUNT_FAIL,
  USER_REMOVE_ACCOUNT_RESET,
  USER_PROFILE_MOOD,
} from "./constants"

export const agibUserRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload,
      }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const agibUsersGetReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USERS_GET_REQUEST:
      return { ...state, loading: true }
    case USERS_GET_SUCCESS:
      return { loading: false, users: action.payload }
    case USERS_GET_FAIL:
      return { loading: false, users: [], error: action.payload }
    default:
      return state
  }
}

export const agibUserProfileReducer = (state = { user: {}, mood: null }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { ...state, loading: true }
    case USER_PROFILE_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case USER_PROFILE_FAIL:
      return { ...state, loading: false, error: action.payload }
    case USER_PROFILE_MOOD:
      return { ...state, mood: action.payload }
    default:
      return state
  }
}

export const agibUserEditReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { loading: true }
    case USER_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      }
    case USER_EDIT_FAIL:
      return { ...state, loading: false, error: action.payload }
    case USER_EDIT_RESET:
      return {}
    default:
      return state
  }
}

export const agibUserAddAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADD_ACCOUNT_REQUEST:
      return { loading: true }
    case USER_ADD_ACCOUNT_SUCCESS:
      return { loading: false, message: action.payload }
    case USER_ADD_ACCOUNT_FAIL:
      return { loading: false, error: action.payload }
    case USER_ADD_ACCOUNT_RESET:
      return {}
    default:
      return state
  }
}

export const agibUserRemoveAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REMOVE_ACCOUNT_REQUEST:
      return { loading: true }
    case USER_REMOVE_ACCOUNT_SUCCESS:
      return { loading: false, message: action.payload }
    case USER_REMOVE_ACCOUNT_FAIL:
      return { loading: false, error: action.payload }
    case USER_REMOVE_ACCOUNT_RESET:
      return {}
    default:
      return state
  }
}
