import "./App.css"
import React from "react"
import { Provider } from "react-redux"
import RouteConfiguration from "./infrastructure/routing"
import store from "./services/store"
import Theme from "./infrastructure/theme"
import { GlobalStyle } from "./components/StyledComponents"
const App = () => {
  return (
    <>
      <Provider store={store}>
        <Theme>
          <GlobalStyle />
          <RouteConfiguration />
        </Theme>
      </Provider>
    </>
  )
}

export default App
