import React from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { FaUserAlt } from "react-icons/fa"
import { MdOutlinePayments, MdAdminPanelSettings, MdDashboard } from "react-icons/md"
import { BsFillBackspaceReverseFill } from "react-icons/bs"

import { SideNavMenu, SideNavList, SideNaveListItem, TitleText, MenuIcon } from "./SideNavStyledComponent"

const home_menu = [
  {
    icon: <MdDashboard />,
    title: "Dashbaord",
    path: "/",
  },
  {
    icon: <MdOutlinePayments />,
    title: "Payments",
    path: "/payment",
  },
  {
    icon: <BsFillBackspaceReverseFill />,
    title: "Reversals",
    path: "/reversal",
  },
  {
    icon: <FaUserAlt />,
    title: "Profile",
    path: "/profile",
  },
  {
    icon: <MdAdminPanelSettings />,
    title: "Admin",
    path: "/admin",
  },
]

const SideNavMenuBarComponent = () => {
  // const { user_info } = useSelector(state => state.agibUserLogin)
  const location = useLocation()

  return (
    <SideNavMenu>
      <TitleText>Overview</TitleText>
      <SideNavList>
        {home_menu.map((item, index) => (
          <Link to={item.path} style={{ textDecoration: "none" }} key={index}>
            <SideNaveListItem key={index} active={item.path === location.pathname}>
              <MenuIcon active={item.path === location.pathname}> {item.icon} </MenuIcon>
              <TitleText>{item.title}</TitleText>
            </SideNaveListItem>
          </Link>
        ))}
      </SideNavList>
    </SideNavMenu>
  )
}

export default SideNavMenuBarComponent
