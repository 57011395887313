import axios from "axios"
import { logoutUserAction } from "../services/authentication/actions"
let store

export const injectReduxStoreToAxios = _store => {
  store = _store
}

axios.interceptors.request.use(req => {
  const { token } = store.getState().agibUserLogin.user_info
  if (req.url !== "/api/user/login") {
    req.headers.authorization = `Bearer ${token}`
  }
  return req
})

axios.interceptors.response.use(
  res => res,
  error => {
    if (error.response && error.response.status && error.response.status === 401) {
      store.dispatch(logoutUserAction())
    }

    return Promise.reject(error)
  }
)
