export const dark = {
  brand: {
    primary: "#616f39",
    secondary: "#5282BD",
    muted: "#C6DAF7",
  },
  ui: {
    primary: "#616f39",
    secondary: "#757575",
    tertiary: "#F1F1F1",
    quaternary: "#FFFFFF",
    disabled: "#DEDEDE",
    error: "#D0421B",
    success: "#138000",
    shadow: "rgba(254, 254, 254, 0.8)",
  },
  bg: { primary: "#121212" },
  foreground: { primary: "#272727", secondary: "#263238" },
  text: {
    primary: "#e0e0e0",
    secondary: "#757575",
    disabled: "#9C9C9C",
    inverse: "#FFFFFF",
    error: "#D0421B",
    success: "#138000",
  },
}

export const light = {
  brand: {
    primary: "#616f39",
    secondary: "#5282BD",
    muted: "#C6DAF7",
  },
  ui: {
    primary: "#616f39",
    secondary: "#757575",
    tertiary: "#F1F1F1",
    quaternary: "#FFFFFF",
    disabled: "#DEDEDE",
    error: "#D0421B",
    success: "#138000",
    shadow: "rgba(0, 0, 0, 0.8)",
  },
  bg: { primary: "#eeeeee" },
  foreground: { primary: "#ffffff", secondary: "#e0e0e0 " },
  text: {
    primary: "#272727",
    secondary: "#757575",
    disabled: "#9C9C9C",
    inverse: "#FFFFFF",
    error: "#D0421B",
    success: "#138000",
  },
}
