import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
 body {
   background: ${props => props.theme.colors.bg.primary};
  }


`
export const MainBody = styled.div`
  position: relative;
  margin-left: 15vw;
  transition: margin-left 0.5s;
  padding: 2rem;
  border-color: grey;
  max-width: 100%;
  min-height: 95vh;
  max-height: 95vh;
`
export const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.brand.primary};
  padding: 0.5rem;
  max-width: 100%;
  margin-bottom: 15px;
  height: fit-content;
  transition: 0.2s;
  /* cursor: pointer; */

  &:hover {
    background: ${props => props.theme.colors.foreground.primary};
  }
`

export const CustomCard = styled(Card)`
  align-items: center;
  box-shadow: -1px -1px 6px 0 ${props => props.theme.colors.ui.shadow};
  /* border-style: none; */
  border-radius: 3px;
  border-color: grey;
  justify-content: center;
  padding: 1rem;
  transition: 0.3s;
  /* elevation: 4dp; */
  background-color: ${props => props.theme.colors.foreground.primary};
  &:hover {
  }
`

export const DashMenuItemTitle = styled("p")`
  text-align: center;
  text-decoration-style: none;
  color: ${props => props.theme.colors.text.primary};
`

export const AbsoluteCenterContainer = styled.div`
  background-color: ${props => props.theme.colors.brand.primary};
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 0.1rem;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 3px solid ${props => props.theme.colors.brand.primary};

  @media ${props => props.theme.device.mobile_small} {
  }
  @media ${props => props.theme.device.mobile_medium} {
    max-width: 75vw;
  }
  @media ${props => props.theme.device.mobile_large} {
    max-width: 75vw;
  }
  @media ${props => props.theme.device.tablet} {
    max-width: 75vw;
  }
`
export const CustomIcon = styled("span")`
  color: ${props => props.theme.colors.brand.primary};
  font-size: ${props => props.theme.font_sizes.icon};
`

export const FormInputField = styled.input`
  color: black;
  background-color: white;
  width: 100%;
  height: 5vh;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: light;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 4px;
  border: 0.1rem solid ${props => props.theme.colors.brand.primary};
  transition: 0.2s;

  &:focus {
    border: 2px solid green;
  }
`

export const FormSubmitButton = styled.button`
  color: ${props => props.theme.colors.text.primary};
  font-size: 16px;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 5px;
  border: 2px solid ${props => props.theme.colors.brand.primary};
  transition: 0.8s;
  width: fit-content;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme.colors.brand.primary};
    color: white;
    font-size: 24px;
  }
`
export const HorizontalLine = styled("hr")`
  color: ${props => props.theme.colors.brand.primary};
`

export const HeaderTextFour = styled("h4")`
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.font_sizes.h4};
`
