import { THEME_MOOD } from "./constants"

export const themeReducer = (state = { mood: false }, action) => {
  switch (action.type) {
    case THEME_MOOD:
      return { mood: action.payload }
    default:
      return state
  }
}
